<template>
  <a-modal
    v-if="active"
    width="650px"
    class="justification-modal"
    :dialog-style="{ display: 'none' }"
    :footer="null"
    :closable="true"
    :visible="active"
    @cancel="onExit">
    <h3 class="flex align-center txt-32 txt-bold">
      {{ translatedTitle }}
    </h3>
    <table>
      <tr class="txt-font-din-medium">
        <th style="width: 40%">
          {{
            $t('components.labels.justificationParameters', {
              indicator: title
            })
          }}
        </th>
        <th>{{ $tc('values.value', 1) }}</th>
        <th>{{ $t('components.labels.justificationBased') }}</th>
      </tr>
      <tr v-for="row in rows" :key="row.saveKey">
        <td style="width: 40%">{{ row.name }}</td>
        <td>{{ row.value }}</td>
        <td>
          <a-textarea v-model="payload[row.saveKey]" :disabled="isSupervisor" :auto-size="{ minRows: 2, maxRows: 6 }"/>
        </td>
      </tr>
    </table>
    <div class="flex justify-space-between w100">
      <a-button
        class="mt-24 w170"
        type="primary" ghost
        size="large"
        @click="onExit">
        {{ $t('values.cancel') }}
      </a-button>
      <a-button
        class="mt-24 w170"
        type="primary"
        size="large"
        :disabled="isSupervisor"
        @click="save">
        {{ $t('values.save') }}
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'JustificationModal',
  props: {
    sampling: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    onExit: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      payload: {}
    };
  },
  computed: {
    ...mapState({
      metadata: state => state.survey.metadata,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    }),
    isAntro() {
      return this.type === 'anthro';
    },
    title() {
      return this.isAntro ? 'Anthropometry' : 'Mortality';
    },
    translatedTitle() {
      if (this.title === 'Mortality') {
        return this.$t('components.labels.mortalityJustification');
      }
      return this.$t('components.labels.anthropometryJustification');
    },
    rows() {
      if (this.isAntro) {
        const prevalenceJustification = {
          name: this.$t('components.description.estimatedPrevalence'),
          value: this.sampling.anthroPrevalence,
          saveKey: 'anthroPrevalenceJustification'
        };
        const precisionJustification = {
          name: this.$t('components.description.desiredPrecision'),
          value: this.sampling.antroPercision,
          saveKey: 'antroPercisionJustification'
        };
        const effectJustification = {
          name: this.$t('components.description.designEffect'),
          value: this.sampling.antroEffect,
          saveKey: 'antroEffectJustification'
        };
        const householdSizeJustification = {
          name: this.$t('components.description.averageHouseholdSize'),
          value: this.sampling.anthroHouseholdSize,
          saveKey: 'anthroHouseholdSizeJustification'
        };
        const underFiveJustification = {
          name: this.$t('components.description.percentageOfChildrenUnderFive'),
          value: this.sampling.antroUnderFive,
          saveKey: 'antroUnderFiveJustification'
        };
        const nonResponseJustification = {
          name: this.$t('components.description.nonResponseHousehold'),
          value: this.sampling.antroNonresponse,
          saveKey: 'antroNonresponseJustification'
        };

        return [
          prevalenceJustification,
          precisionJustification,
          effectJustification,
          householdSizeJustification,
          underFiveJustification,
          nonResponseJustification
        ];
      }

      const rateJustification = {
        name: this.$t('components.description.estimatedDeathRatePerTenKDay'),
        value: this.sampling.mortalityRate,
        saveKey: 'mortalityRateJustification'
      };
      const precisionJustification = {
        name: this.$t('components.description.desiredPrecisionPerTenKDay'),
        value: this.sampling.mortalityPrecision,
        saveKey: 'mortalityPrecisionJustification'
      };
      const effectJustification = {
        name: this.$t('components.description.designEffect'),
        value: this.sampling.mortalityEffect,
        saveKey: 'mortalityEffectJustification'
      };
      const householdSizeJustification = {
        name: this.$t('components.description.averageHouseholdSize'),
        value: this.sampling.mortalityHouseholdSize,
        saveKey: 'mortalityHouseholdSizeJustification'
      };
      const recallJustification = {
        name: this.$t('components.description.recallPeriodDays'),
        value: this.sampling.mortalityRecall,
        saveKey: 'mortalityRecallJustification'
      };
      const nonResponseJustification = {
        name: this.$t('components.description.nonResponseHousehold'),
        value: this.sampling.mortalityNonresponse,
        saveKey: 'mortalityNonresponseJustification'
      };
      return [
        rateJustification,
        precisionJustification,
        effectJustification,
        householdSizeJustification,
        recallJustification,
        nonResponseJustification
      ];
    }
  },
  created() {
    if (this.metadata) {
      const keys = this.rows.map(row => row.saveKey);
      keys.forEach(key => {
        this.payload[key] = this.metadata[key];
      });
    }
  },
  methods: {
    ...mapActions([
      'setMetadata'
    ]),
    save() {
      const values = {
        ...this.metadata,
        ...this.payload
      };
      this.setMetadata(values);
      this.onExit();
    }
  }
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;

  th {
    font-weight: bold;
  }

  td,th {
    padding: 10px;
  }

  tr {
    padding: 10px;
  }
}
</style>
